<template>
  <aside v-show="isAsideVisible" class="aside is-placed-left is-expanded">
    <aside-tools :is-main-menu="true">
      <span slot="label"> ບໍລິສັດ <b>ກຽງໄກ</b> ຂົນສົ່ງ </span>
    </aside-tools>
    <div class="menu is-menu-main">
      <template v-for="(menuGroup, index) in menu">
        <p v-if="typeof menuGroup === 'string'" :key="index" class="menu-label">
          {{ menuGroup }}
        </p>
        <aside-menu-list
          v-else
          :key="index"
          :menu="menuGroup"
          @menu-click="menuClick"
        />
      </template>
    </div>
  </aside>
</template>

<script>
import AsideTools from "@/components/AsideTools";
import AsideMenuList from "@/components/AsideMenuList";
import { get } from "vuex-pathify";

export default {
  name: "AsideMenu",
  components: { AsideTools, AsideMenuList },
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    isAsideVisible: get("isAsideVisible"),
  },
  methods: {
    menuClick(item) {

      //isAsideMobileExpanded
      this.$store.commit('asideMobileStateToggle')
      console.log(item);
    },
  },
};
</script>
