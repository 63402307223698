<template>
  <html
    class="
      has-aside-mobile-transition
      has-aside-left
      has-navbar-fixed-top
      has-aside-expanded
    "
  >
    <body>
      <nav-bar />
      <aside-menu :menu="menu" />
      <router-view />
    </body>
  </html>
</template>

<script>
import NavBar from "@/components/NavBar";
import AsideMenu from "@/components/AsideMenu";
import { get } from "vuex-pathify";
export default {
  components: {
    AsideMenu,
    NavBar,
  },
  computed: {
    username: get("username"),
    user: get("user"),
    menu() {
      const m = [
        [
          {
            to: "/home",
            icon: "desktop-mac",
            label: "ໜ້າຫຼັກ",
          },
          {
            to: "/addshipment",
            label: "ຮັບຝາກເຄື່ອງ",
            icon: "truck",
            updateMark: true,
          },
          {
            to: "/loadparcelstobus",
            label: "ເອົາເຄື່ອງຂຶ້ນລົດ",
            icon: "arrow-up",
          },
          {
            to: "/ShipmentScript",
            label: "ໃບນໍາສົ່ງ",
            icon: "table",
          },
          {
            to: "/LoadParcelsFromBus",
            label: "ກວດເຄື່ອງລົງລົດ",
            icon: "arrow-down",
          },
          {
            to: "/toDeliverParcels",
            label: "ເອົາເຄື່ອງໃຫ້ລູກຄ້າ",
            icon: "hand",
          },
          {
            to: "/canceledshipment",
            label: "ເຄື່ອງຖືກຍົກເລີກການສົ່ງ",
            icon: "file-document-box-remove-outline",
          },
          {
            to: "/holdingshipment",
            label: "ເຄື່ອງຖືກອາຢັດ",
            icon: "car-brake-hold",
          },
          {
            to: "/rejectshipment",
            label: "ປ່ຽນປາຍທາງ",
            icon: "call-split",
          },
          {
            label: "ລູກຄ້າ",
            icon: "account-group",
            to: "/customers",
          },
          {
            label: "ເຄື່ອງນອກສະຖານທີ່",
            icon: "map-marker-path",
            to: "/pickup-request-list",
          },
          {
            label: "ເຄື່ອງທີ່ຕ້ອງອອກໄປຮັບ",
            icon: "clock-check-outline",
            to: "/pickup-list",
          },
          {
            label: "ລົງບັນຊີຮັບ-ຈ່າຍ",
            icon: "file-table-outline",
            to: "/ledgers",
          },
        ],
        "ລາຍງານການຂົນສົ່ງ",
        [
          {
            label: "ພັດສະດຸຂອງສາຂາ",
            icon: "timeline-text-outline",
            to: "/shipmentfrombranch",
          },
          {
            label: "ພັດສະດຸສົ່ງມາສາຂາ",
            icon: "home-analytics",
            to: "/shipmenttosbranch",
          },
          {
            label: "ລາຍການCODຄ້າງຢູ່ສາຂາ",
            icon: "home-analytics",
            to: "/codondesbranch",
          },
          {
            label: "ເຄື່ອງຄ້າງຢູ່ສາຂາ",
            icon: "vector-point",
            to: "/receiverpayondesbranch",
          },
          /* {
            label: "ຕາມວັນທີ",
            icon: "timetable",
            to: "/report-shipments",
          },*/
          {
            label: "ຕາມສະຖານະ",
            icon: "format-list-bulleted-type",
            to: "/report-shipmentsstatus",
          },
          {
            label: "ເຄື່ອງຖືກຕີກັບມາສາຂາ",
            icon: "atom-variant",
            to: "/shipmentrejecttosbranch",
          },

          {
            label: "ຈ່າຍເຄື່ອງ COD",
            icon: "cash-marker",
            to: "/report-codshipments",
          },
          {
            label: "ຄ່າຝາກ ເກັບຕົ້ນທາງ",
            icon: "page-next-outline",
            to: "/report-sendparcelsfee",
          },
          {
            label: "ຄ່າຝາກ ເກັບປາຍທາງ",
            icon: "page-previous-outline",
            to: "/report-receiveparcelsfee",
          },
          {
            label: "ສະຫລຸບ ເງິນຕ້ອງສົ່ງ",
            icon: "sigma",
            to: "/summary-settle-of-branch",
          },
        ],
      ];
      if (this.user && this.user.role == "ADMIN") {
        m.push("ການຕັ້ງຄ່າ");
        m.push([
          {
            to: "/cod",
            label: "ໂອນ COD",
            icon: "cash",
          },
          {
            label: "ອັດຕາແລກປ່ຽນ",
            icon: "cash",
            to: "/exchangerates",
          },
          {
            label: "ສາຂາ",
            icon: "source-branch",
            to: "/branches",
          },
          {
            label: "ລາຄາຂົນສົ່ງ",
            icon: "cash",
            to: "/prices",
          },
          {
            label: "ປະເພດເຄື່ອງຝາກ",
            icon: "box",
            to: "/parceltypes",
          },
          {
            label: "ລົດຂົນເຄື່ອງ",
            icon: "truck",
            to: "/vehicles",
          },
          {
            label: "ພະນັກງານ",
            icon: "view-list",
            to: "/users",
          },
          {
            label: "ແຂວງ",
            icon: "view-list",
            to: "/provinces",
          },
          {
            label: "ເມືອງ",
            icon: "view-list",
            to: "/districts",
          },
          {
            label: "ທະນາຄານ",
            icon: "view-list",
            to: "/banks",
          },
        ]);
      }
      return m;
    },
  },
};
</script>