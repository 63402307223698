<template>
  <nav v-show="isNavBarVisible" id="navbar-main" class="navbar is-fixed-top is-light">
    <div class="navbar-brand">
      <a
        class="navbar-item is-hidden-desktop"
        @click.prevent="menuToggleMobile"
      >
        <b-icon :icon="menuToggleMobileIcon" />
      </a>
      <div class="navbar-item has-control no-left-space-touch">
        <div class="control">
          <b-input
            v-model="keyword"
            placeholder="ຊອກຫາ ເລກໃບບິນ, tracking ..."
            style="background-color:cornflowerblue"
            @keyup.native.enter="search()"
          />
        </div>
      </div>

      <a
        v-if="isAndroid"
        class="navbar-item is-hidden-desktop"
        @click.prevent="doNavBarScannedBarcode"
      >
        <b-icon icon="barcode" />
      </a>
    </div>
    <div class="navbar-brand is-right">
      <a
        class="navbar-item navbar-item-menu-toggle is-hidden-desktop"
        @click.prevent="menuNavBarToggle"
      >
        <b-icon :icon="menuNavBarToggleIcon" custom-size="default" />
      </a>
    </div>
    <div
      class="navbar-menu fadeIn animated faster"
      :class="{ 'is-active': isMenuNavBarActive }"
    >
      <div class="navbar-end" v-if="user">
        <a class="navbar-item" style="color: #111111" @click="changeRate()">
          <country-flag :country="'th'" />
          {{ exchangerate["THB"] | formatnumber }} ກີບ
        </a>
        <a class="navbar-item" style="color: #111111" @click="changeRate()">
          <country-flag :country="'us'" />
          {{ exchangerate["USD"] | formatnumber }} ກີບ
        </a>
        <nav-bar-menu class="has-divider has-user-avatar">
          <user-avatar />
          <div class="is-user-name">
            <span>{{ user.branch.code }} {{ user.branch.name }}</span>
          </div>
          <div class="is-user-name">{{ " - " }}</div>
          <div class="is-user-name">
            <span>{{ user.username }}</span>
          </div>
          <div slot="dropdown" class="navbar-dropdown">
            <router-link
              to="/profile"
              class="navbar-item"
              exact-active-class="is-active"
            >
              <b-icon icon="account" custom-size="default" />
              <span>ຂໍ້ມູນສ່ວນຕົວ</span>
            </router-link>
            <hr class="navbar-divider" />
            <router-link
              to="/changepassword"
              class="navbar-item"
              exact-active-class="is-active"
            >
              <b-icon icon="key" custom-size="default" />
              <span>ປ່ຽນລະຫັດຜ່ານ</span>
            </router-link>
          </div>
        </nav-bar-menu>
        <a
          style="color: #111111"
          class="navbar-item is-desktop-icon-only"
          title="Log out"
          @click="logout"
        >
          <b-icon icon="logout" custom-size="default" />
          ອອກ
        </a>
      </div>
    </div>

    <div class="modal is-active is-large" v-if="isShowModalExchangeRate">
      <div class="modal-background"></div>
      <div class="modal-card" v-if="rate">
        <header class="modal-card-head">
          <p class="modal-card-title">ອັດຕາແລກປ່ຽນ (ສະເພາະສາຂາ)</p>
          <button
            class="delete"
            aria-label="close"
            @click="isShowModalExchangeRate = false"
          ></button>
        </header>
        <section class="modal-card-body">
          <b-field label="ບາດ" horizontal>
            <b-input
              v-model="rate.THB"
              placeholder="ຈຳນວນທຽບເທົ່າກີບ"
              size="is-large"
              required
            />
          </b-field>
          <b-field label="ໂດລາ" horizontal class="is-large">
            <b-input
              v-model="rate.USD"
              placeholder="ຈຳນວນທຽບເທົ່າກີບ"
              size="is-large"
              required
            />
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <b-button
            class="is-success"
            slot="right"
            @click="saveExchangeRate()"
            icon-left="check"
          >
            ບັນທຶກ
          </b-button>
        </footer>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState } from "vuex";
import NavBarMenu from "@/components/NavBarMenu";
import UserAvatar from "@/components/UserAvatar";
import { get } from "vuex-pathify";
import swal from "sweetalert2";

export default {
  name: "NavBar",
  components: {
    UserAvatar,
    NavBarMenu,
  },
  data() {
    return {
      isMenuNavBarActive: false,
      isShowModalExchangeRate: false,
      keyword: null,
      rate: {
        THB: null,
        USD: null,
      },
    };
  },
  computed: {
    user: get("user"),
    exchangerate: get("exchangerate"),
    menuNavBarToggleIcon() {
      return this.isMenuNavBarActive ? "close" : "dots-vertical";
    },
    menuToggleMobileIcon() {
      return this.isAsideMobileExpanded ? "backburger" : "forwardburger";
    },
    ...mapState(["isNavBarVisible", "isAsideMobileExpanded"]),
  },
  mounted() {
    this.$router.afterEach(() => {
      this.isMenuNavBarActive = false;
    });
  },
  methods: {
    saveExchangeRate() {
      this.exchangerate.THB = this.rate.THB;
      this.exchangerate.USD = this.rate.USD;
      this.$store.set("exchangerate", this.exchangerate);
      this.isShowModalExchangeRate = false;
      swal.fire("ສຳເລັດ", "", "success");
    },
    changeRate() {
      this.isShowModalExchangeRate = true;
      this.rate = {
        THB: this.exchangerate["THB"],
        USD: this.exchangerate["USD"],
      };
    },
    search() {
      const k = this.keyword.trim().split(/\//).pop();
      console.log("k", k);
      this.keyword = null;
      if (k && k.length > 0) {
        if (this.$route.name != "viewshipmentform") {
          this.$router.push(`/shipment/${k}`);
        } else {
          if (this.$route.path != "/shipment/" + k) {
            this.$router.push(`/shipment/${k}`);
          } else {
            this.$router.go(`/shipment/${k}`);
          }
        }
      }
    },
    menuToggleMobile() {
      this.$store.commit("asideMobileStateToggle");
    },
    menuNavBarToggle() {
      this.isMenuNavBarActive = !this.isMenuNavBarActive;
    },
    logout() {
      this.$buefy.snackbar.open({
        message: "Log out clicked",
        queue: false,
      });
      this.$store.set("user", null);
      this.$store.set("token", null);
      setTimeout(() => {
        this.$router.replace("/login");
      }, 1000);
    },

    doNavBarScannedBarcode() {
      this.$buefy.snackbar.open({
        message: "Scan barcode",
        queue: false,
      });

      try {
        return window.AndroidApp.scanBarcode("nbBarcodeScanned");
      } catch (e) {
        console.log(e);
      }
    },
    navBarScannedBarcode(barcode) {
      if (barcode !== undefined && barcode.length > 0) {
        this.keyword = barcode;
        this.search();
      }
    },
  },
  beforeMount() {
    //Create interface between Webview and Native application
    window["MyWebView"] = {
      components: this,
      nbBarcodeScanned: (barcode) => this.navBarScannedBarcode(barcode),
    };
  },
};
</script>
